/* =============================================================================


     index


============================================================================= */
.main._top {
  position: relative;
  margin-top: 0;
  overflow: hidden;
}

.mv {
  position: relative;
  padding-bottom: 80px;
}

.mv:before, .mv:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0px;
  width: calc(50% + 290px);
  height: 1200px;
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
}

@media (max-width: 767px) {
  .mv:before, .mv:after {
    width: 60%;
    height: 40%;
  }
}

.mv:before {
  background-size: contain;
  background: transparent linear-gradient(0deg, #fff 0%, #eeece9 3%, #cac2b9 10%, #a99b8c 17%, #8c7a66 25%, #745e46 33%, #60482c 42%, #513618 52%, #472a0a 63%, #402302 77%, #3f2100 100%) 0% 0% no-repeat padding-box;
  background-repeat: no-repeat;
}

.mv:after {
  opacity: 0.56;
  background-size: contain;
  background: url(../asset/images/top/top_bg02.svg);
  background-repeat: repeat;
  background-size: 284px 285.8551px;
}

.mv-inner {
  position: relative;
}

.mv-inner:before {
  content: "";
  top: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: calc(100% + 300px);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
  z-index: -1;
  background-size: contain;
  background: url(../asset/images/top/top_bg01.svg);
  background-repeat: repeat;
  background-size: 432px;
}

@media (max-width: 767px) {
  .mv-inner:before {
    height: calc(100% + 25vw);
  }
}

.mv-info {
  max-width: 200px;
  margin-top: 32px;
}

.mv-list {
  display: flex;
  align-items: flex-start;
  border-top: 1px solid #fff;
  margin-top: 30px;
  padding-top: 24px;
  justify-content: flex-start;
  flex-direction: row-reverse;
}

@media (max-width: 767px) {
  .mv-list {
    display: none;
  }
}

.mv-child {
  background: #fff;
  color: #47220d;
  width: 110%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  position: absolute;
  top: calc(100% - 20px);
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}

.mv-child:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-style: solid;
  border-bottom: 9px solid #fff;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 0;
}

.mv-child-item {
  width: 50%;
}

.mv-child-item + li {
  border-right: 1px solid #47220d;
}

.mv-child-item a {
  writing-mode: tb;
  font-feature-settings: normal;
  transition: 0.2s;
  width: 100%;
  padding: 2em 0;
  display: flex;
  align-items: center;
}

.mv-child-item a:hover {
  color: #fff;
  background: #47220d;
}

.mv-item {
  width: 33.33333%;
  text-align: center;
  padding-bottom: 30px;
  position: relative;
}

.mv-item._child:hover .mv-child {
  opacity: 1;
  visibility: visible;
}

.mv-item-link {
  transition: 0.2s;
  position: relative;
  padding-top: 1.5em;
  writing-mode: tb;
  font-weight: 900;
  font-size: 2.2rem;
  font-feature-settings: normal;
  text-shadow: 1px 1px 3px #47220d, -1px -1px 3px #47220d, -1px 1px 3px #47220d, 1px -1px 3px #47220d, 0px 1px 3px #47220d, 0 -1px 3px #47220d, -1px 0 3px #47220d, 1px 0 3px #47220d;
  width: 100%;
  display: flex;
  align-items: center;
}

.mv-item-link:before {
  content: "";
  background-image: url(../asset/images/top/butahana_w.svg);
  background-position: center;
  background-size: contain;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  width: 25px;
  height: 20px;
  background-repeat: no-repeat;
  opacity: 0;
  transition: 0.2s;
}

.mv-item-link:hover:before {
  opacity: 1;
}

.ramen-imgbox01 {
  max-width: 1022px;
  position: relative;
  margin-left: auto;
  right: -370px;
  width: 100vw;
  top: -140px;
  position: absolute;
}

@media (max-width: 767px) {
  .ramen-imgbox01 {
    right: -140px;
    top: -50px;
    max-width: 95vw;
  }
}

.ramen-imgbox01 .ramen-yuge01 {
  mix-blend-mode: screen;
  position: absolute;
  top: 50%;
  left: 54%;
  transform: translate(-50%, -50%);
  max-width: 1246px;
  width: 120%;
  height: 120%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ramen-imgbox02 {
  max-width: 1092px;
  position: relative;
  margin-right: auto;
  left: -540px;
  width: 100vw;
  position: absolute;
  top: 500px;
}

@media (max-width: 767px) {
  .ramen-imgbox02 {
    left: -140px;
    top: 160px;
    max-width: 95vw;
  }
}

.ramen-imgbox02 .ramen-yuge02 {
  mix-blend-mode: screen;
  position: absolute;
  top: 50%;
  left: 54%;
  transform: translate(-50%, -50%);
  max-width: 1136px;
  width: 110%;
  height: 110%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ramen-img01 {
  max-width: 1022px;
}

@media (max-width: 767px) {
  .ramen-img01 {
    max-width: 420px;
  }
}

.ramen-img02 {
  max-width: 1092px;
}

@media (max-width: 767px) {
  .ramen-img02 {
    max-width: 420px;
  }
}

.mv-txtbox {
  position: relative;
  z-index: 2;
  display: flex;
  color: #fff;
  gap: 120px;
}

@media (max-width: 767px) {
  .mv-txtbox {
    flex-direction: column;
    letter-spacing: 2.8px;
    gap: 20px;
  }
}

.mv-info {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.mv-logo {
  width: 190px;
}

@media (max-width: 767px) {
  .mv-logo {
    width: 70px;
  }
}

.mv-head {
  font-size: 4.2rem;
  font-weight: 900;
  color: #fff;
  writing-mode: tb;
  font-feature-settings: normal;
  margin-top: 140px;
}

@media (max-width: 767px) {
  .mv-head {
    font-size: 2rem;
    writing-mode: horizontal-tb;
    color: #47220d;
    line-height: 2;
    margin-top: 0;
  }
  .mv-head span {
    background: #fff;
  }
}

/* **************************************


     puckup


***************************************/
.pickup {
  max-width: 800px;
  margin-left: auto;
  position: relative;
  z-index: 2;
  margin-top: 160px;
}

@media (max-width: 767px) {
  .pickup {
    text-align: center;
    margin: 200px auto 0;
  }
}

.pickup-ttl {
  background: #47220d;
  display: inline-block;
  font-size: 9rem;
  font-weight: 900;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
  color: #fff;
  font-weight: 900;
  font-style: italic;
  letter-spacing: 16.2px;
  line-height: 1;
  padding: 0 0.25em;
}

@media (max-width: 767px) {
  .pickup-ttl {
    font-size: 3rem;
  }
}

.pickup-item {
  background: #fff;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 28px;
  margin-top: 28px;
}

@media (max-width: 767px) {
  .pickup-item {
    gap: 14px;
    padding: 8px;
    margin-top: 14px;
  }
}

.pickup-img {
  border: 4px solid #47220d;
  position: relative;
  width: 32%;
}

@media (max-width: 767px) {
  .pickup-img {
    border: 2px solid #47220d;
  }
}

.pickup-img:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.pickup-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  object-fit: cover;
  font-family: "object-fit:cover";
}

@media (max-width: 767px) {
  .pickup-img img {
    width: calc(100% - 5px);
    height: calc(100% - 5px);
  }
}

.pickup-txtbox {
  width: calc(68% - 28px);
  color: #47220d;
  text-align: justify;
}

@media (max-width: 767px) {
  .pickup-txtbox {
    width: calc(70% - 14px);
  }
}

.pickup-txtbox a {
  text-decoration: underline;
  transition: 0.2s;
}

.pickup-txtbox a:hover {
  opacity: 0.7;
}

.pickup-date {
  font-size: 2.2rem;
  font-weight: bold;
  font-family: "Roboto", "Noto Sans JP", sans-serif;
}

@media (max-width: 767px) {
  .pickup-date {
    font-size: 1.4rem;
  }
}

.pickup-txt {
  font-size: 2rem;
  margin-top: 1em;
  line-height: 1.75;
  font-weight: bold;
}

@media (max-width: 767px) {
  .pickup-txt {
    font-size: 1.4rem;
    margin-top: 0em;
  }
}

/* **************************************


     comm


***************************************/
.comm {
  padding: 80px 0;
}

.comm-inner {
  width: 1366px;
  max-width: 100%;
}

.comm-list {
  margin-top: -10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 767px) {
  .comm-list {
    flex-direction: column;
  }
}

.comm-item {
  width: 36%;
}

@media (max-width: 767px) {
  .comm-item {
    width: 80%;
    margin-top: 24px;
  }
  .comm-item:nth-child(2) {
    margin-left: auto;
  }
  .comm-item:nth-child(3) {
    margin: 24px 0 0 0;
  }
}

.comm-item:nth-child(3) {
  margin: -40px auto 0;
}

.comm-btnbox {
  margin: 60px auto 0;
}

@media (max-width: 767px) {
  .comm-btnbox {
    margin-top: 40px;
  }
}

/* **************************************


     menu


***************************************/
.menubox {
  background: url(../asset/images/top/menu-bg.jpg);
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 120px 0 200px;
}

@media (max-width: 767px) {
  .menubox {
    padding: 60px 0 100px;
  }
}

.menubox:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.5;
}

.menubox .section-ttl,
.menubox .menu-txt {
  text-shadow: 1px 1px 3px #fff, -1px -1px 3px #fff, -1px 1px 3px #fff, 1px -1px 3px #fff, 0px 1px 3px #fff, 0 -1px 3px #fff, -1px 0 3px #fff, 1px 0 3px #fff;
}

.menu-txt {
  margin-top: 74px;
  text-align: center;
  font-size: 3.4rem;
  font-weight: bold;
}

@media (max-width: 767px) {
  .menu-txt {
    font-size: 1.6rem;
    margin-top: 40px;
  }
}

.point {
  background: #e3dbc9;
  position: relative;
  padding: 40px 0 80px;
  z-index: 1;
}

.point:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: url(../asset/images/top/top_bg02.svg);
  background-repeat: repeat;
  background-size: contain;
  background-size: 284px 285.8551px;
  opacity: 1;
}

.point-txtbox {
  position: relative;
  z-index: 2;
}

@media (max-width: 767px) {
  .point-txtbox {
    text-align: center;
  }
}

.point-head {
  width: 210px;
  position: absolute;
  top: -90px;
  left: -24px;
}

@media (max-width: 767px) {
  .point-head {
    width: 140px;
    top: -60px;
  }
}

.point-ttl {
  font-size: 4.6rem;
  font-weight: bold;
}

@media (max-width: 767px) {
  .point-ttl {
    font-size: 3.2rem;
    text-align: center;
  }
}

.point-name {
  font-size: 3.4rem;
  margin-top: 0.5em;
  font-weight: bold;
}

@media (max-width: 767px) {
  .point-name {
    font-size: 2.4rem;
    text-align: center;
  }
}

.point-ttlbox {
  display: inline-block;
  position: relative;
}

.point-txt {
  font-size: 2.2rem;
  font-weight: bold;
  margin-top: 1em;
  position: relative;
  z-index: 1;
  line-height: 2.36364;
}

@media (max-width: 1228px) {
  .point-txt {
    font-size: 1.8rem;
  }
}

@media (max-width: 767px) {
  .point-txt {
    font-size: 1.6rem;
    text-align: left;
  }
}

.point-imgbox {
  max-width: 1020px;
  margin-right: -230px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 80vw;
}

@media (max-width: 767px) {
  .point-imgbox {
    position: relative;
    margin: 24px auto 0;
    top: auto;
    transform: none;
  }
}

.point-btnbox {
  margin-top: 100px;
}

.point-btnbox .btn {
  margin-right: auto;
  margin-left: 0;
}

@media (max-width: 767px) {
  .point-btnbox {
    margin-top: 60px;
  }
  .point-btnbox .btn {
    margin: auto;
  }
}

.ramen-yuge03 {
  mix-blend-mode: screen;
  position: absolute;
  top: 0%;
  left: 54%;
  transform: translateX(-50%);
  max-width: 1336px;
  width: 100%;
  height: 100%;
  display: flex;
  top: -10%;
  justify-content: center;
  align-items: center;
}
